import { Box } from 'braid-design-system';

export const GalleryItem = ({ url }: { url: string }) => (
  <Box
    role="img"
    borderRadius="standard"
    style={{
      backgroundImage: `url(${url})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      paddingTop: '100%',
    }}
  />
);
