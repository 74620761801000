import { Box } from 'braid-design-system';
import React from 'react';
import { QuarterOneShape } from '../images/QuarterOneShape';
import { QuarterThreeShape } from '../images/QuarterThreeShape';

// Hard-coded color is used because does not exist in braid theme colors
export const ContactUsDecoration = () => (
  <>
    <Box
      style={{ position: 'absolute', top: 0, right: 100 }}
      display={{ mobile: 'none', desktop: 'block' }}
    >
      <QuarterOneShape color={'#072462'} />
    </Box>
    <Box
      display={{ mobile: 'none', desktop: 'block' }}
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: '100px',
      }}
    >
      <QuarterThreeShape color="#E60278" />
    </Box>
  </>
);
