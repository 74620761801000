import type { DocumentItemFragment, Icon } from '@seek/cmsu-cms-connect';
import { Box, Heading, Secondary, Stack, Text } from 'braid-design-system';
import { renderIcon } from '../IconBlockGroup/renderer';
import { formatAbsoluteDatePreferredFormat } from '../../helpers/dateHelper';

type DocumentItemProps = DocumentItemFragment & {
  overrideIcon?: Icon | null;
};

export const DocumentItem = ({
  overrideIcon,
  itemIcon,
  date,
  title,
  externalUrl,
}: DocumentItemProps) => {
  const linkProps = !externalUrl
    ? {}
    : {
        component: 'a',
        href: externalUrl.externalUrl,
        target: '_blank',
        rel: 'noopener noreferrer',
      };

  let icon;
  if (overrideIcon) {
    icon = overrideIcon;
  } else if (!overrideIcon && itemIcon) {
    icon = itemIcon;
  }

  return (
    <Box
      boxShadow="borderNeutralLight"
      borderRadius="large"
      display="flex"
      alignItems="center"
      {...(linkProps as React.ComponentProps<typeof Box>)}
    >
      {icon && <Box padding="medium">{renderIcon(icon)}</Box>}
      <Box paddingY="medium" paddingRight="medium">
        <Stack space="small">
          <Heading level="4">{title}</Heading>
          {date && (
            <Text size="small">
              <Secondary>{formatAbsoluteDatePreferredFormat(date)}</Secondary>
            </Text>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
