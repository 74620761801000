import {
  HeaderLayout,
  type CustomHeader,
  type Section,
} from '@seek/cmsu-cms-connect';

interface HeaderProps {
  type: 'SeekHeader' | 'CustomHeader';
  layout?: HeaderLayout;
  logo?: CustomHeader['logo'];
  links?: CustomHeader['links'];
  divider?: CustomHeader['divider'];
}

export const getHeaderConfig = (
  headerOptions: Section['headerOptions'],
): HeaderProps => {
  if (headerOptions?.headerType?.__typename === 'CustomHeader') {
    return {
      type: 'CustomHeader',
      logo: headerOptions.headerType.logo,
      links: headerOptions.headerType.links,
      divider: headerOptions.headerType.divider,
    };
  }

  if (headerOptions?.headerType?.__typename === 'SeekHeader') {
    return {
      type: 'SeekHeader',
      layout: headerOptions.headerType.layout || HeaderLayout.Full,
    };
  }

  return { type: 'SeekHeader' };
};
