import type { FormFragment } from '@seek/cmsu-cms-connect';
import {
  blockRenderers,
  CForm,
  CustomComponentRenderer,
  type BlockItemRenderers,
} from '@seek/cmsu-components';
import { useConfig } from '../hooks/useConfig';
import { WPT_SALESFORCE_API } from 'src/config';

export const pageBlockRenderers: BlockItemRenderers = {
  ...blockRenderers.hirer,
  CCustomComponent: ({ data }, additionalData) => {
    const { country, locale, recaptchaKey } = useConfig();
    return data ? (
      <CustomComponentRenderer
        data={data}
        renderers={{
          ...blockRenderers.customComponentRenderers,

          CForm: ({ form }) => (
            <CForm
              locale={locale}
              sourceName={'/sara'}
              privacyUrl={'/privacy'}
              recaptchaKey={recaptchaKey}
              wptSalesForceApi={WPT_SALESFORCE_API}
              country={country}
              form={form as FormFragment}
            />
          ),
        }}
        additionalData={additionalData}
      />
    ) : null;
  },
};
