import {
  Box,
  Column,
  Columns,
  Hidden,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import React, { type MouseEvent, useState } from 'react';

import { carousel, carouselItem } from './GalleryCarousel.css';
import { BackgroundColor } from '@seek/cmsu-cms-connect';
import { GalleryItem } from '../GalleryItem/GalleryItem';
import { NavButton } from '../NavButton/NavButton';
import type { AssetType } from '../../Gallery';

export const GalleryCarousel = ({ assets }: { assets: AssetType[] }) => {
  const MAX_ITEMS_SHOWN = 3;

  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const isLastItem =
    currentItemIndex + (MAX_ITEMS_SHOWN - 1) >= assets.length - 1;
  const isLastItemMobile = currentItemIndex >= assets.length - 1;
  const isFirstItem = currentItemIndex <= 0;
  const ref = React.createRef<HTMLElement>();

  const moveItemsLeft = (event: MouseEvent, itemsToMove: number = 1) => {
    event.preventDefault();
    if (isFirstItem) {
      return;
    }

    setCurrentItemIndex(currentItemIndex - itemsToMove);
  };

  const moveItemsRight = (event: MouseEvent, mobile: boolean = false) => {
    event.preventDefault();
    if (mobile ? isLastItemMobile : isLastItem) {
      return;
    }

    setCurrentItemIndex(currentItemIndex + 1);
  };

  return (
    <Stack space="large">
      <Hidden below="desktop">
        <Columns space="small" align="center" alignY="center">
          <Column width="content" hideBelow="tablet">
            <NavButton
              direction="left"
              disabled={isFirstItem}
              onClick={moveItemsLeft}
              tone={BackgroundColor.BrandAccent}
            />
          </Column>
          <Column>
            <Box className={carousel} ref={ref}>
              {assets
                .slice(currentItemIndex, currentItemIndex + MAX_ITEMS_SHOWN)
                .map((asset, itemIndex) => (
                  <Box
                    key={itemIndex}
                    position="relative"
                    className={carouselItem}
                    height="full"
                  >
                    <GalleryItem url={asset.url} />
                  </Box>
                ))}
            </Box>
          </Column>
          <Column width="content" hideBelow="tablet">
            <NavButton
              direction="right"
              disabled={isLastItem}
              onClick={moveItemsRight}
              tone={BackgroundColor.BrandAccent}
            />
          </Column>
        </Columns>
      </Hidden>
      <Hidden above="tablet">
        <Box
          className={carousel}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          {assets
            .slice(currentItemIndex, currentItemIndex + 1)
            .map((asset, itemIndex) => (
              <Box
                key={itemIndex}
                position="relative"
                className={carouselItem}
                height="full"
              >
                <GalleryItem url={asset.url} />
              </Box>
            ))}
        </Box>
        <Inline space="none" align="center" alignY="center">
          <NavButton
            direction="left"
            disabled={isFirstItem}
            onClick={moveItemsLeft}
            tone={BackgroundColor.BrandAccent}
          />
          <Text weight="strong">
            {currentItemIndex + 1} of {assets.length}
          </Text>
          <NavButton
            direction="right"
            disabled={isLastItemMobile}
            onClick={(e) => moveItemsRight(e, true)}
            tone={BackgroundColor.BrandAccent}
          />
        </Inline>
      </Hidden>
    </Stack>
  );
};
