import type { GetPagesQuery, Section } from '@seek/cmsu-cms-connect';
import { CustomPageHeader } from '@seek/cmsu-components';
import { Box, Page } from 'braid-design-system';
import type React from 'react';
import { Footer } from '../../modules/Footer/Footer';
import { Helm } from '../../modules/Helmet/Helmet';
import { SaraHeader } from '../../modules/SaraHeader/SaraHeader';
import { getHeaderConfig } from '../../../helpers/getHeaderConfig';
import { useConfig } from '../../../hooks/useConfig';

export type Props = { page: GetPagesQuery['pages'][number] };

const PageLayout = ({ page, children }: React.PropsWithChildren<Props>) => {
  const { country, hygraphLocale, routeArray, product } = useConfig();

  const { title, relatedPageSection } = page;
  const { headerOptions } = relatedPageSection || {};
  const { logo, links, type } = getHeaderConfig(
    headerOptions as Section['headerOptions'],
  );

  const filteredLinks = links?.map((link) => {
    if (link.link?.link?.to?.__typename === 'Page') {
      const removeSlugPrefix = link.link.link.to.slug.replace(/(sara-)/g, '');
      link.link.link.to.slug = removeSlugPrefix;
    }

    return link;
  });

  return (
    <Page footer={<Footer />}>
      <Helm title={title} />
      {type === 'CustomHeader' ? (
        <CustomPageHeader
          language="en"
          logo={logo}
          links={filteredLinks}
          country={country}
          locale={hygraphLocale}
          route={routeArray}
          theme={product}
          siteNav={false}
          alignLinks="right"
        />
      ) : (
        // Provide fallback to 'old' header to ease transition post-deployment
        <SaraHeader />
      )}
      <Box>{children}</Box>
    </Page>
  );
};

export { PageLayout };
