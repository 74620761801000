/* For use when tracking an event eg., page view, form submit */
import { adapter } from '@seek/tealium-adapter';
import type { PageTrackingDataLayer } from './TealiumAdapterTypes';

export const tealiumAdapter = adapter(undefined);

export const initTealiumTracker = (eventProperties: PageTrackingDataLayer) => {
  setPageTrackingData(eventProperties);
};

export const getPageTrackingData = () => {
  if (typeof window !== 'undefined') {
    return window.currentPageTrackingData;
  }
  return {};
};

const setPageTrackingData = (eventProperties: PageTrackingDataLayer) => {
  if (typeof window !== 'undefined') {
    window.currentPageTrackingData = {
      ...window.currentPageTrackingData,
      ...eventProperties,
    };
  }
};

export const trackEvent = (
  eventName: string,
  eventProperties: PageTrackingDataLayer,
) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter is not initialised');
  }
  const pageTrackingDataLayer = {
    ...getPageTrackingData(),
    ...eventProperties,
    eventName,
  };
  setPageTrackingData(pageTrackingDataLayer);
  tealiumAdapter.view(eventName, pageTrackingDataLayer);
};

export const deHyphen = (str?: string) => str?.replace(/-/g, ' ') || '';
