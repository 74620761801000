/* eslint-disable no-console */
import type { BannerArticleFragment } from '@seek/cmsu-cms-connect';
import {
  Badge,
  Box,
  ContentBlock,
  Stack,
  Heading,
  Text,
  TextLink,
  IconArrow,
} from 'braid-design-system';
import React, { type FunctionComponent } from 'react';
import * as styles from './ArticleBanner.css';
import { resizeImageFromHygraph } from '../../../utils/imageHelper';
import { articleActionByType } from '../../../helpers/articleHelper';
import { useTranslations } from '@vocab/react';
import translationsArticle from '../../ArticleItem/.vocab';
import translations from './.vocab';

type Props = {
  banner: BannerArticleFragment;
};

const ArticleBanner: FunctionComponent<Props> = ({ banner }) => {
  const { article } = banner;
  const navigateToArticle = (url?: string) => {
    window.open(url, '_blank');
  };
  const articleAction = articleActionByType(article?.articleType);
  const { t } = useTranslations(translationsArticle);
  const { t: tran } = useTranslations(translations);
  return (
    <ContentBlock>
      <Box
        margin={{ desktop: 'none', mobile: 'none' }}
        marginY={{ desktop: 'small', mobile: 'none' }}
        href={article?.url || ''}
        boxShadow="borderNeutralLight"
        borderRadius="standard"
        className={styles.container}
        data-testid="articleBannerTestId"
        onClick={() => navigateToArticle(article?.url)}
      >
        <Box
          boxShadow="small"
          display={{ mobile: 'flex' }}
          flexDirection={{ desktop: 'row', mobile: 'columnReverse' }}
          width="full"
        >
          <Box
            padding={{ desktop: 'xlarge', mobile: 'large' }}
            className={styles.featTextContainer}
          >
            <Stack space={{ desktop: 'xlarge', mobile: 'none' }}>
              <Stack space={{ desktop: 'medium', mobile: 'gutter' }}>
                {article && article.category && (
                  <Badge tone="neutral">{article.category.name}</Badge>
                )}
                <Heading level="3">{article?.title}</Heading>
                {Boolean(article?.timeToRead) && (
                  <Text size="small" tone="secondary">
                    {`${article?.timeToRead} ${t(articleAction)}`}
                  </Text>
                )}
              </Stack>
              <Box
                display={{ desktop: 'inlineBlock', mobile: 'none' }}
                paddingTop="small"
              >
                <Text tone="neutral" size="standard" weight="medium">
                  <TextLink
                    icon={<IconArrow direction="right" />}
                    iconPosition="trailing"
                    href={article?.url || ''}
                    weight="weak"
                    target="_blank"
                  >
                    {`${tran('readarticle')}`}
                  </TextLink>
                </Text>
              </Box>
            </Stack>
          </Box>
          <Box className={styles.featImageContainer}>
            {article && (
              <img
                className={styles.featImage}
                src={resizeImageFromHygraph({
                  imageUrl: article.featureImage[0]?.url,
                  width: 550,
                  height: 295,
                })}
                alt="test"
                style={{
                  display: 'block',
                  width: '100%',
                }}
                data-testid="assetTestId"
              />
            )}
          </Box>
        </Box>
      </Box>
    </ContentBlock>
  );
};

export { ArticleBanner };
