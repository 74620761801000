import type { Locale } from '@seek/cmsu-cms-connect';

/**
 * Check if the skipDefaultLocale is true and then compare
 * the itemLocale is not the same as the siteLocale.
 */
export const checkSkipDefaultLocale = (
  skipDefaultLocale: boolean | null | undefined,
  itemLocale: Locale,
  siteLocale: Locale,
) => skipDefaultLocale && itemLocale !== siteLocale;
