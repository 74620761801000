import {
  DocumentListFilter as DocumentListFilterOption,
  type DocumentItemFragment,
  type DocumentListFragment,
} from '@seek/cmsu-cms-connect';
import {
  Box,
  Dropdown,
  Hidden,
  Inline,
  Pagination,
  Stack,
  Text,
} from 'braid-design-system';
import { DocumentItem } from './DocumentItem';
import { useEffect, useState } from 'react';
import { paginationHelper } from '../../helpers/paginationHelper';
import translations from '../../.vocab';
import { useTranslations } from '@vocab/react';

export const DocumentList = ({
  icon,
  documents,
  usePagination: enablePagination = true,
  maxPageSize,
  documentFilterOption = DocumentListFilterOption.NoFilter,
}: DocumentListFragment) => {
  const pageSize = maxPageSize ? maxPageSize : 20;
  const [documentItems, setDocumentItems] = useState<
    Array<DocumentItemFragment>
  >([]);
  const [totalDocuments, setTotalDocuments] = useState<number>(
    documents.length,
  );
  const [totalPages, setTotalPages] = useState<number>(
    paginationHelper.getTotalPages(totalDocuments, pageSize),
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedFilter, setSelectedFilter] = useState<number>(-1);

  useEffect(() => {
    const filteredItems = documentListService.filterDocumentListItemsBy(
      documents,
      selectedFilter,
      documentFilterOption,
    );

    setTotalDocuments(filteredItems.length);
    setTotalPages(paginationHelper.getTotalPages(totalDocuments, pageSize));

    if (!enablePagination || totalDocuments <= pageSize) {
      setDocumentItems(filteredItems);
    } else {
      const { startIndex, lastIndex } =
        paginationHelper.getArrayStartAndLastIndexForCurrentPage(
          currentPage,
          totalDocuments,
          pageSize,
        );

      setDocumentItems(filteredItems.slice(startIndex, lastIndex));
    }
  }, [
    currentPage,
    documents,
    selectedFilter,
    totalDocuments,
    documentFilterOption,
    enablePagination,
    pageSize,
  ]);

  const availableYears = documentListService.getAvailableYears(documents);

  const showFilter =
    documentFilterOption !== DocumentListFilterOption.NoFilter &&
    availableYears.length > 0;

  const showPagination =
    enablePagination && totalDocuments > pageSize && currentPage <= totalPages;

  return (
    <Stack space="gutter">
      {showFilter && (
        <DocumentListFilter
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          availableYears={availableYears}
        />
      )}

      <Stack space="xsmall">
        {documentItems.map((item) => (
          <DocumentItem key={item.id} {...item} overrideIcon={icon} />
        ))}
      </Stack>

      {showPagination && (
        <Pagination
          page={currentPage}
          total={totalPages}
          label="pagination"
          linkProps={({ page: pageInPagination }) => ({
            href: '#',
            onClick: (e) => {
              e.preventDefault();

              setCurrentPage(pageInPagination);
            },
          })}
        />
      )}
    </Stack>
  );
};

type DocumentListFilterProps = {
  selectedFilter: number;
  setSelectedFilter: (value: number) => void;
  availableYears: number[];
};

// Only require to filter by Year at the moment
// Refactoring needed for support to different filtering options
const DocumentListFilter = ({
  selectedFilter: selectedYear,
  setSelectedFilter: setSelectedYear,
  availableYears,
}: DocumentListFilterProps) => {
  const { t } = useTranslations(translations);

  const label = <Text>{t('Filter by year')}</Text>;
  const dropDownFilter = (
    <Dropdown
      id="DOCUMENT_LIST_FILTER"
      label=""
      value={selectedYear}
      onChange={(event) => {
        setSelectedYear(Number(event.currentTarget.value));
      }}
    >
      <>
        <option value="-1">All</option>
        {availableYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </>
    </Dropdown>
  );
  return (
    <>
      <Hidden above="mobile">
        <Stack space="small">
          <>{label}</>

          <>{dropDownFilter}</>
        </Stack>
      </Hidden>
      <Hidden below="tablet">
        <Inline space="small" alignY="center">
          <>{label}</>

          <Box>{dropDownFilter}</Box>
        </Inline>
      </Hidden>
    </>
  );
};

const getDocumentForYear = (documents: DocumentItemFragment[], year: number) =>
  documents.filter(
    (document) =>
      document.date && new Date(document.date).getFullYear() === year,
  );
const filterDocumentListItemsBy = (
  documents: DocumentItemFragment[],
  selectedFilter: number,
  documentListFilterOption: DocumentListFilterOption | null,
) => {
  switch (documentListFilterOption) {
    case DocumentListFilterOption.Year:
      return documentListService.filterDocumentListItemsBySelectedYear(
        documents,
        selectedFilter,
      );
    default:
      return documents;
  }
};

const filterDocumentListItemsBySelectedYear = (
  documents: DocumentItemFragment[],
  selectedYear: number,
) =>
  selectedYear === -1
    ? documents
    : documentListService.getDocumentForYear(documents, selectedYear);

const getAvailableYears = (documents: DocumentItemFragment[]) =>
  Array.from(
    new Set(documents.map((document) => new Date(document.date).getFullYear())),
  ).filter((year) => !isNaN(year));

export const documentListService = {
  getDocumentForYear,
  getAvailableYears,
  filterDocumentListItemsBy,
  filterDocumentListItemsBySelectedYear,
};
