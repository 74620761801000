import type {
  ActionGroupFragment,
  BannerItemFragment,
  HeadingFragment,
  ParagraphFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Heading, Stack } from 'braid-design-system';
import { ActionGroup } from '../ActionGroup/ActionGroup';
import { Paragraph } from '../Paragraph/Paragraph';
import type { BraidHeadingProps } from '../types';
import { DecorationRenderer } from './decorations/DecorationRenderer';

interface BannerItemProps {
  item: BannerItemFragment;
}

export const BannerItem = ({ item }: BannerItemProps) => {
  const {
    c_actionGroup,
    backgroundColor,
    borderRadius,
    heading,
    paragraph,
    bannerItemDecoration,
  } = item;
  // TODO: Make the padding flexible through hygraph
  return (
    <Box
      background={backgroundColor || 'brand'}
      borderRadius={{ mobile: 'none', desktop: borderRadius || 'standard' }}
      paddingX={{ tablet: 'xlarge', mobile: 'medium' }}
      paddingY={{ tablet: 'xlarge', mobile: 'large' }}
      style={{
        position: 'relative',
        overflow: 'hidden', // Prevents the decoration from overflowing
      }}
    >
      <BannerItemContent heading={heading} paragraph={paragraph} />
      {c_actionGroup && <BannerItemActionGroup actionGroup={c_actionGroup} />}

      <DecorationRenderer decorationType={bannerItemDecoration} />
    </Box>
  );
};

const BannerItemContent = ({
  heading,
  paragraph,
}: {
  heading: HeadingFragment | null | undefined;
  paragraph: ParagraphFragment | null | undefined;
}) => (
  <Stack space="medium">
    <Heading
      level={heading?.level.toString() as BraidHeadingProps['level']}
      weight={heading?.weight ?? 'regular'}
    >
      {heading?.text}
    </Heading>
    <Paragraph {...paragraph} content={paragraph?.Paragraph_text?.raw} />
  </Stack>
);

const BannerItemActionGroup = ({
  actionGroup,
}: {
  actionGroup: ActionGroupFragment;
}) => {
  const { actions, display, inline } = actionGroup;
  return (
    <Box paddingTop={{ mobile: 'small', desktop: 'medium' }}>
      <ActionGroup actions={actions} display={display} inline={inline} />
    </Box>
  );
};
