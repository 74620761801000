import React from 'react';
import { Box, Text, Link, Inline, ContentBlock } from 'braid-design-system';
import { useCustomHeaderContext } from '../CustomHeaderProvider';
import { trackLink } from '../helpers';
import * as styles from '../styles.css';

export const links = [
  { text: 'Jobs', href: '/' },
  {
    text: 'Courses',
    href: '/learning/?campaigncode=seek_banner_29&sc_trk=skj-courses-link',
    countryOverride: 'au',
  },
  {
    text: 'Businesses for sale',
    href: 'https://www.seekbusiness.com.au/?site=au&tracking=sk%3Amain%3Aau%3Anav%3Abus',
  },
  {
    text: 'Volunteering',
    href: 'https://www.volunteer.com.au/?tracking=SKMAU%3Amain+header',
  },
];

export const AnzSiteNav = () => {
  const { country } = useCustomHeaderContext();

  return (
    <Box background="neutralLight">
      <ContentBlock>
        <Inline space="gutter" align="center">
          {links.map(({ text, href, countryOverride }, i: number) => {
            if (countryOverride && countryOverride !== country) return null;

            return <SiteLink key={i} text={text} href={href} />;
          })}
        </Inline>
      </ContentBlock>
    </Box>
  );
};

const SiteLink = ({ text, href }: { text: string; href: string }) => (
  <Link
    href={href}
    onClick={() =>
      trackLink('navigation_pressed', {
        linkName: 'navigation_pressed',
        linkPosition: 'header',
        linkText: text,
      })
    }
  >
    <Box paddingY="xsmall">
      <Text size="xsmall" tone="secondary">
        <span className={styles.linkText}>{text}</span>
      </Text>
    </Box>
  </Link>
);
