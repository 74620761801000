import {
  Inline,
  ButtonIcon,
  IconSocialFacebook,
  IconSocialLinkedIn,
  IconMail,
  IconPrint,
  IconSocialX,
} from 'braid-design-system';
import { useCallback } from 'react';
import { generateSocialShareUrl } from '../../helpers/socialShareHelper';

type Props = {
  pageUrl: string;
  summary: string;
  title: string;
};

const sharedIconProps: Partial<React.ComponentProps<typeof ButtonIcon>> = {
  size: 'large',
  variant: 'soft',
};

const SocialMediaShare = ({ pageUrl, title, summary }: Props) => {
  const facebookUri = generateSocialShareUrl('facebook', { pageUrl, title });
  const twitterUri = generateSocialShareUrl('twitter', { pageUrl, title });
  const linkedinUri = generateSocialShareUrl('linkedin', { pageUrl, title });
  const mailUri = encodeURI(
    `mailto:?subject=Sharing '${title}'&body="${summary}..." ${pageUrl}`,
  );

  const onSocialTagClick = useCallback(
    (url: string) => () => {
      window.open(url, '_blank');
    },
    [],
  );

  return (
    <Inline space="small" alignY="center">
      <ButtonIcon
        {...sharedIconProps}
        id="social-facebook-tag"
        icon={<IconSocialFacebook />}
        label="Share to Facebook"
        onClick={onSocialTagClick(facebookUri)}
      />
      <ButtonIcon
        {...sharedIconProps}
        id="social-twitter-tag"
        icon={<IconSocialX />}
        label="Share to twitter"
        onClick={onSocialTagClick(twitterUri)}
      />
      <ButtonIcon
        {...sharedIconProps}
        id="social-linkedin-tag"
        icon={<IconSocialLinkedIn />}
        label="Share to Linkedin"
        onClick={onSocialTagClick(linkedinUri)}
      />
      <ButtonIcon
        {...sharedIconProps}
        id="print-tag"
        icon={<IconPrint />}
        label="Print"
        onClick={() => window.print()}
      />
      <ButtonIcon
        {...sharedIconProps}
        id="mail-tag"
        icon={<IconMail />}
        label="Send Email"
        onClick={onSocialTagClick(mailUri)}
      />
    </Inline>
  );
};

export { SocialMediaShare };
