import React from 'react';
import { GalleryCarousel } from './components/GalleryCarousel/GalleryCarousel';
import type {
  AssetFragment,
  DefaultImageUrlTransformationFragment,
} from '@seek/cmsu-cms-connect';

export type AssetType = AssetFragment & DefaultImageUrlTransformationFragment;

interface GalleryProps {
  assets: AssetType[];
}

export const Gallery = ({ assets }: GalleryProps) => (
  <GalleryCarousel assets={assets} />
);
