// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'Show more': () => 'Show more' | '[Šḩöööŵ m̂öööřẽẽẽ]';
    'Show less': () => 'Show less' | '[Šḩöööŵ ƚẽẽẽšš]';
    'Filter by year': () =>
      | 'Filter by year'
      | 'ตัวกรองตามปี'
      | 'Filter berdasarkan tahun'
      | '[Ƒììƚṯẽẽř ßýý ýýẽẽăăř]';
    Menu: () => 'Menu' | 'เมนู' | '[Ṃẽẽกี้ǚǚ]';
  }
>({
  en: createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  th: createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'ตัวกรองตามปี',
    Menu: 'เมนู',
  }),
  id: createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter berdasarkan tahun',
    Menu: 'Menu',
  }),
  zh: createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'en-AU': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'en-HK': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'en-ID': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'en-MY': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'en-NZ': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'en-PH': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'en-SG': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'en-TH': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'th-TH': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'ตัวกรองตามปี',
    Menu: 'เมนู',
  }),
  'id-ID': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter berdasarkan tahun',
    Menu: 'Menu',
  }),
  'zh-HK': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
  }),
  'en-PSEUDO': createLanguage({
    'Show more': '[Šḩöööŵ m̂öööřẽẽẽ]',
    'Show less': '[Šḩöööŵ ƚẽẽẽšš]',
    'Filter by year': '[Ƒììƚṯẽẽř ßýý ýýẽẽăăř]',
    Menu: '[Ṃẽẽกี้ǚǚ]',
  }),
});

export default translations;
