const getTotalPages = (totalRecords: number, maxPageSize: number) =>
  totalRecords > 0 ? Math.ceil(totalRecords / maxPageSize) : 1;

const getArrayStartAndLastIndexForCurrentPage = (
  currentPage: number,
  totalItems: number,
  pageSize: number,
) => {
  const startIndex = (currentPage - 1) * pageSize;
  const lastIndex = Math.min(startIndex + pageSize, totalItems);
  return { startIndex, lastIndex };
};

export const paginationHelper = {
  getTotalPages,
  getArrayStartAndLastIndexForCurrentPage,
};
