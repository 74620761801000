import type {
  BorderRadius,
  BoxShadows,
  BraidSizes,
  CBoxBackground,
  ContainersFragment,
} from '@seek/cmsu-cms-connect';
import type { Box } from 'braid-design-system';

/**
 * Checks if a container should have a divider between it and the next container.
 */
export const hasDivider = (
  containers: ContainersFragment[],
  currentIndex: number,
): boolean => {
  const thisContainer = containers[currentIndex];
  const nextContainer = containers[currentIndex + 1];

  // Don't add a divider if this or the next container have a background
  if (thisContainer?.box?.background || nextContainer?.box?.background) {
    return false;
  }

  // Don't add a divider if we are on the last container
  if (currentIndex === containers.length - 1) {
    return false;
  }

  return true;
};

const colourMap: Record<string, string> = {
  lightPurple: '#F0D6FA',
  lightCyan: '#E1F8FA',
  lightTurquoise: '#BEEFF3',
};

type BoxBackgroundProps = {
  background?: React.ComponentProps<typeof Box>['background'];
  style: Record<string, any>;
};

type BoxBlockBorderProps = {
  boxShadow?: React.ComponentProps<typeof Box>['boxShadow'];
};

type BoxBorderRadiusProps = {
  borderRadius?: React.ComponentProps<typeof Box>['borderRadius'];
};

type BoxPaddingHeightProps = {
  paddingY?: React.ComponentProps<typeof Box>['paddingY'];
};

type BoxPaddingWidthProps = {
  paddingX?: React.ComponentProps<typeof Box>['paddingX'];
};

export const getBoxBackgroundProps = (background?: CBoxBackground | null) => {
  const backgroundProps: BoxBackgroundProps = {
    background: undefined,
    style: { background: undefined },
  };

  if (background == null) {
    return backgroundProps;
  }

  if (background && colourMap[background]) {
    backgroundProps.style.background = colourMap[background];
  } else if (background === 'customBg') {
    backgroundProps.background = 'brand';
    backgroundProps.style.position = 'relative';
  } else {
    backgroundProps.background =
      (background as React.ComponentProps<typeof Box>['background']) ||
      undefined;
  }

  return backgroundProps;
};

export const getBlockBorderProps = (blockBorder?: BoxShadows | null) => {
  const borderProps: BoxBlockBorderProps = {
    boxShadow: undefined,
  };

  if (blockBorder == null) {
    return borderProps;
  }

  borderProps.boxShadow =
    (blockBorder as React.ComponentProps<typeof Box>['boxShadow']) || undefined;

  return borderProps;
};

export const getBorderRadiusProps = (borderRadius?: BorderRadius | null) => {
  const borderProps: BoxBorderRadiusProps = {
    borderRadius: undefined,
  };

  if (borderRadius == null) {
    return borderProps;
  }

  borderProps.borderRadius =
    (borderRadius as React.ComponentProps<typeof Box>['borderRadius']) ||
    undefined;

  return borderProps;
};

export const getBoxWidthProps = (boxWidth?: BraidSizes | null) => {
  const boxWidthProps: BoxPaddingWidthProps = {
    paddingX: undefined,
  };

  if (boxWidth == null) {
    return boxWidthProps;
  }

  boxWidthProps.paddingX =
    (boxWidth as React.ComponentProps<typeof Box>['paddingX']) || undefined;

  return boxWidthProps;
};

export const getBoxHeightProps = (boxHeight?: BraidSizes | null) => {
  const boxHeightProps: BoxPaddingHeightProps = {
    paddingY: undefined,
  };

  if (boxHeight == null) {
    return boxHeightProps;
  }

  boxHeightProps.paddingY =
    (boxHeight as React.ComponentProps<typeof Box>['paddingY']) || undefined;

  return boxHeightProps;
};
