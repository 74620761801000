import { CustomDecoration } from '@seek/cmsu-cms-connect';
import React from 'react';
import { ContactUsDecoration } from './ContactUsDecoration';

export const DecorationRenderer = ({
  decorationType,
}: {
  decorationType: CustomDecoration | null | undefined;
}) => {
  switch (decorationType) {
    case CustomDecoration.ContactUsBannerDeco:
      return <ContactUsDecoration />;
    default:
      return null;
  }
};
