import {
  BackgroundColor,
  CTextAlign,
  CTextSize,
  CTextTone,
  type StepBlockFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Spread, Stack } from 'braid-design-system';
import React from 'react';
import { Heading } from '../../Heading/Heading';
import { Paragraph } from '../../Paragraph/Paragraph';

import * as styles from '../KeyStepsBlock.css';
import { vars } from 'braid-design-system/css';

interface Props {
  tone?: BackgroundColor;
  steps?: StepBlockFragment[];
}

export const VerticalStepper = ({ tone, steps }: Props) => (
  <Box position="relative" paddingLeft="xxxlarge">
    <Stack space="none">
      {steps &&
        steps.map((item, i) => (
          <StepItem
            key={i}
            tone={tone}
            heading={item.heading}
            paragraph={item.paragraph}
            last={i === steps.length - 1}
          />
        ))}
    </Stack>
  </Box>
);

interface StepItemProps {
  tone: Props['tone'];
  heading?: NonNullable<Props['steps']>[number]['heading'];
  paragraph?: NonNullable<Props['steps']>[number]['paragraph'];
  last: boolean;
}

const StepItem = ({ heading, paragraph, tone, last }: StepItemProps) => (
  <Box
    className={styles.stepItem}
    position="relative"
    paddingY="medium"
    background="surface"
    style={{
      color: vars.backgroundColor[tone || BackgroundColor.Neutral],
    }}
  >
    <Box
      className={styles.stepLine}
      style={{
        color: last
          ? 'transparent'
          : vars.backgroundColor[tone || BackgroundColor.Neutral],
      }}
    >
      <Box paddingLeft="small" role="listitem">
        <Spread space="small" direction="vertical">
          {heading && <Heading {...heading} />}
          {paragraph && (
            <Paragraph
              content={paragraph.Paragraph_text?.raw}
              align={paragraph.align || CTextAlign.Left}
              tone={paragraph.tone || CTextTone.Secondary}
              size={paragraph.size || CTextSize.Standard}
            />
          )}
        </Spread>
      </Box>
    </Box>
  </Box>
);
