import type { RenderParams } from '@seek/forms-ui';
import {
  Box,
  Button,
  FieldMessage,
  Heading,
  Stack,
  Text,
  TextLink,
  Alert,
  Inline,
  IconPositive,
  Tiles,
} from 'braid-design-system';
import type { UseField } from '@seek/forms-ui/lib/Field/useField';
import type { Language } from '@seek/forms-ui/lib/types';
import { CFormType, type FormFragment } from '@seek/cmsu-cms-connect';

import { RenderField } from './RenderField';
import React, { useState } from 'react';
import type { Country } from '@seek/melways-sites';
import ReCAPTCHA from 'react-google-recaptcha';
import type { DynamicFormValues, FormField } from './types';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import { RichText } from '@seek/cmsu-rich-text';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { EmailContentComponent } from './EmailContentComponent';
import { renderEmail } from 'react-html-email';

type FormProps = RenderParams<DynamicFormValues>;

type TileColumns = 1 | 2 | 3 | 4 | 5 | 6 | undefined;

export interface ComponentProps extends FormProps {
  useField: UseField<DynamicFormValues, Language>;
  country: Country;
  locale: string;
  wptSalesForceApi: string;
  resetForm: () => void;
  sourceName: string;
  privacyUrl: string;
  form: FormFragment;
  recaptchaKey: string;
}

export const Form = ({
  handleSubmit,
  useField,
  resetForm,
  wptSalesForceApi,
  country,
  sourceName,
  form,
  recaptchaKey,
}: ComponentProps) => {
  const { t } = useTranslations(translations);
  const isMailChimpForm = form.formType === CFormType.MailChimp;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const { language } = useCMSUContext();

  const onSubmit = handleSubmit((formValues: DynamicFormValues) => {
    if (!recaptchaValue) {
      setRecaptchaError('Please verify that you are not a robot');
      return;
    }

    let data;
    let url;

    switch (form.formType) {
      case CFormType.SaraEnquiry:
        data = {
          from: 'saras@seek.com.au',
          to: ['saras@seek.com.au'],
          subject: 'SARAs Enquiry',
          message: renderEmail(
            <EmailContentComponent
              name={formValues.name}
              company={formValues.company}
              email={formValues.email}
              enquiry={formValues.enquiry}
              subject="SARAs Enquiry"
            />,
          ),
          recaptcha: recaptchaValue,
        };
        url = `${wptSalesForceApi}/sara`;
        break;
      case CFormType.MailChimp:
        data = {
          ...formValues,
          apiEndpoint: form.apiEndpoint,
          SITECNTRY: country.toUpperCase(),
          recaptcha: recaptchaValue,
        };
        url = `${wptSalesForceApi}/mailChimp-subscription`;
        break;
      default:
        data = {
          ...formValues,
          siteCountry: country.toUpperCase(),
          sourcePage: sourceName,
          recaptcha: recaptchaValue,
        };
        url = `${wptSalesForceApi}/${form.apiEndpoint}`;
    }

    fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setIsSubmitted(true);
        } else {
          resetForm();
          setSubscriptionError(true);
        }
      })
      .catch(() => {
        setSubscriptionError(true);
        resetForm();
      });
  });

  return (
    <form onSubmit={onSubmit} style={{ padding: '0px' }}>
      {!isSubmitted ? (
        <Box>
          <Stack space={'medium'}>
            <Heading level="2">{form.title ?? ''}</Heading>
            <Text>{form.description ?? ''}</Text>
            <Tiles
              columns={{ desktop: form.columns as TileColumns, mobile: 1 }}
              space="medium"
            >
              {form.fields.map((field, key) => (
                <Box key={field.key}>
                  <RenderField field={field} key={key} useField={useField} />{' '}
                </Box>
              ))}
            </Tiles>

            <ReCAPTCHA
              style={{ paddingTop: '0px', marginTop: '0px' }}
              sitekey={recaptchaKey}
              onChange={(val: any) => {
                setRecaptchaValue(val);
                setRecaptchaError('');
              }}
              hl={language}
            />

            {recaptchaError ? (
              <FieldMessage
                id="recaptcha"
                tone="critical"
                message={recaptchaError}
              />
            ) : (
              <></>
            )}
            {form.disclaimerText ? (
              <Text size="xsmall">{form.disclaimerText}</Text>
            ) : (
              <></>
            )}
            {isMailChimpForm && (
              <Text size="xsmall">
                <>
                  You can unsubscribe from emails at any time. By clicking
                  ‘subscribe’ you agree to {' '}
                  <TextLink href="/privacy">SEEK’s Privacy Statement.</TextLink>
                </>
              </Text>
            )}
            <Inline space="none">
              <Button
                type="submit"
                id="submitBtn"
                tone={isMailChimpForm ? 'formAccent' : undefined}
              >
                {form.ctaText || t('Submit')}
              </Button>
            </Inline>

            {subscriptionError && (
              <Box>
                <Alert tone="critical">
                  <Text>Something Wrong</Text>
                </Alert>
              </Box>
            )}
          </Stack>
        </Box>
      ) : (
        <>
          {form.confirmationMessage && isMailChimpForm ? (
            <Box>
              <Stack space="medium">
                <Heading level="2">Subscribe</Heading>
                <Text icon={<IconPositive />}>Thanks for subscribing!</Text>
              </Stack>
            </Box>
          ) : (
            <Box>
              <span data-testid="FormConfirmationMessageTestId">
                <RichText content={form.confirmationMessage?.raw} />
              </span>
            </Box>
          )}
        </>
      )}
    </form>
  );
};

export const gridColumn = (field: FormField) =>
  field.__typename === 'FormTextarea' ||
  field.__typename === 'FormCheckbox' ||
  field.rowSpan
    ? '1 / -1'
    : 'auto';
