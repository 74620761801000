import { Stack, Text, Inline, Box, ContentBlock } from 'braid-design-system';
import React, { useEffect, useState } from 'react';
import type { PromotionalBannerFragment } from '@seek/cmsu-cms-connect';
import { Paragraph } from '../Paragraph/Paragraph';
import { getLogoByBrand } from '../../helpers/getLogoByBrand';
import { Heading } from '../Heading/Heading';
import { useTrackView } from '@seek/cmsu-analytics';

type Props = Pick<
  PromotionalBannerFragment,
  'id' | 'heading' | 'paragraph' | 'brand' | 'background' | 'showLogo'
>;

export const PromotionalBanner = ({
  id,
  brand,
  heading,
  paragraph,
  background,
  showLogo,
}: Props) => {
  const [firstRender, setFirstRender] = useState(false);
  const trackPromoBannerView = useTrackView(id);

  useEffect(() => {
    if (
      trackPromoBannerView &&
      !firstRender &&
      paragraph?.Paragraph_text !== null
    ) {
      trackPromoBannerView();
      setFirstRender(true);
    }
  }, [trackPromoBannerView, firstRender, paragraph?.Paragraph_text]);

  // Allow this component to be rendered by localisations with paragraph content set in the CMS
  // (Currently used to render for Asia sites only)
  // TODO: Revisit this logic for market specific rendering
  // Update code based on the entity Hygraph API, which will remove properties whose values are null.
  if (!paragraph?.Paragraph_text) return null;

  return (
    <Box
      paddingTop={{ mobile: 'large', tablet: showLogo ? 'medium' : 'large' }}
      paddingBottom="large"
      paddingX="large"
      background={background || 'brand'}
      textAlign={{ mobile: 'center', tablet: 'left' }}
    >
      <ContentBlock>
        <Stack space={{ mobile: 'small', tablet: 'xsmall' }}>
          <Inline
            space="xxsmall"
            alignY={'center'}
            align={{ mobile: 'center', tablet: 'left' }}
          >
            <Text size="large" weight="strong">
              {heading && <Heading {...heading} />}
            </Text>
            {showLogo && getLogoByBrand(brand)}
          </Inline>
          {paragraph && (
            <Paragraph
              marginBottom={paragraph.marginBottom}
              align={paragraph.align}
              tone={paragraph.tone}
              content={paragraph.Paragraph_text?.raw}
            />
          )}
        </Stack>
      </ContentBlock>
    </Box>
  );
};
