import type React from 'react';
import { Box, ButtonIcon, IconArrow, IconChevron } from 'braid-design-system';
import { BackgroundColor } from '@seek/cmsu-cms-connect';

enum borderTone {
  'brandAccent' = 'borderBrandAccent',
  'brand' = 'borderInfo',
  'neutral' = 'borderNeutral',
  'neutralSoft' = 'borderNeutralLight',
}

const directionLabel = {
  left: 'Prev',
  right: 'Next',
};

interface NavButtonProps {
  direction: 'left' | 'right';
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
  tone?: BackgroundColor;
  icon?: 'IconChevron' | 'IconArrow';
}
export const NavButton = ({
  direction,
  onClick,
  disabled,
  tone = BackgroundColor.Neutral,
  icon = 'IconChevron',
}: NavButtonProps) => {
  const Icon = icon === 'IconChevron' ? IconChevron : IconArrow;

  return (
    <Box
      data={{
        testid: `carousel-nav-${directionLabel[direction].toLowerCase()}`,
      }}
      borderRadius="full"
      padding="xsmall"
      marginRight={direction === 'left' ? 'small' : 'none'}
      marginLeft={direction === 'right' ? 'small' : 'none'}
      background="surface"
      boxShadow={disabled ? 'borderNeutral' : borderTone[tone]}
      style={{ opacity: disabled ? '0.3' : '1' }}
    >
      {disabled ? (
        <Icon tone="secondary" direction={direction} size="standard" />
      ) : (
        <ButtonIcon
          id={`carousel-${directionLabel[direction]}`}
          icon={<Icon tone="brandAccent" direction={direction} />}
          size="large"
          label={directionLabel[direction]}
          variant="transparent"
          bleed={true}
          onClick={onClick}
        />
      )}
    </Box>
  );
};
