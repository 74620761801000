import { CTextSize, type BannerInlineFragment } from '@seek/cmsu-cms-connect';
import {
  Columns,
  Column,
  Stack,
  Box,
  Badge,
  Inline,
  useResponsiveValue,
} from 'braid-design-system';
import React from 'react';
import { ActionGroup } from '../../ActionGroup/ActionGroup';
import { Asset, AssetType } from '../../Asset/Asset';
import { Heading } from '../../Heading/Heading';
import { Paragraph } from '../../Paragraph/Paragraph';
import { BannerDivider } from '../BannerDivider';

type Props = Pick<
  BannerInlineFragment,
  | 'reverse'
  | 'image'
  | 'heading'
  | 'paragraph'
  | 'actionGroup'
  | 'inlineDivider'
  | 'badge'
  | 'imageHiddenBelow'
  | 'imageBorderRadius'
>;

export const InlineBanner = ({
  actionGroup,
  heading,
  paragraph,
  reverse,
  image,
  inlineDivider,
  badge,
  imageHiddenBelow,
  imageBorderRadius,
}: Props) => {
  /**
   * There are some styling issue when we directly use the <Hidden> component
   * if we wrap the image column with Hidden, it will cause the image size shrink
   * if we wrap the Asset component with Hidden in the Column, it will cause there is
   * unused Column in mobile view and content not vertical center.
   */
  const responsiveValue = useResponsiveValue();
  const isDeskTop = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: false,
    wide: true,
  });
  const isTablet = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: true,
    wide: true,
  });
  const boxPaddingX = imageHiddenBelow === 'desktop' ? 'none' : 'medium';
  const hiddenBelow =
    imageHiddenBelow === 'none' ? 'mobile' : imageHiddenBelow ?? 'mobile';
  const shouldRenderImage = (hidden: string): boolean => {
    let shouldRender = false;
    switch (hidden) {
      case 'mobile':
        shouldRender = true;
        break;
      case 'tablet':
        shouldRender = (isDeskTop || isTablet) ?? false;
        break;
      case 'desktop':
        shouldRender = isDeskTop || false;
        break;
      default:
        break;
    }
    return shouldRender;
  };
  return (
    <Box data-testid="inlineBannerTestId" paddingX={boxPaddingX}>
      <Columns
        space={{ mobile: 'gutter', tablet: 'xlarge' }}
        alignY="center"
        collapseBelow="tablet"
        reverse={reverse || false}
      >
        {shouldRenderImage(hiddenBelow) ? (
          <Column width="1/2">
            {image && (
              <Box
                borderRadius={imageBorderRadius || 'none'}
                style={{ overflow: 'hidden' }}
              >
                <Asset assetType={AssetType.INLINE_BANNER} {...image} />
              </Box>
            )}
          </Column>
        ) : (
          <></>
        )}
        <Column>
          <Stack space="gutter">
            {badge && (
              <Badge {...badge} weight="strong">
                {badge.text}
              </Badge>
            )}
            {heading && <Heading {...heading} />}
            {paragraph && (
              <Paragraph
                content={paragraph.Paragraph_text?.raw}
                {...paragraph}
                size={paragraph.size || CTextSize.Large}
              />
            )}
            {actionGroup && (
              <Inline space="none">
                <ActionGroup
                  {...actionGroup}
                  size={actionGroup.actionGroupSize}
                />
              </Inline>
            )}
            {inlineDivider && (
              <BannerDivider
                image={inlineDivider.image}
                actions={inlineDivider}
              />
            )}
          </Stack>
        </Column>
      </Columns>
    </Box>
  );
};
