import {
  Box,
  Dialog,
  IconCritical,
  Loader,
  Spread,
  Text,
} from 'braid-design-system';
import React, { useEffect, useState } from 'react';

import { Video } from '../Video/Video';

import * as styles from './VideoDialog.css';

export enum VideoSrcType {
  Brightcove = 'BrightcoveVideo',
  YouTube = 'YouTubeVideo',
}

interface Props {
  url: string;
  title?: string;
  description?: string;
}

// TODO: Expand the Dialog options to allow for specific Dialog Title & Description
// TODO: Handle Youtube videos, even though we have very few of them
export const VideoDialog = ({ title, description, url }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [videoData, setVideoData] = useState({
    thumb: '',
    title: '',
    description: '',
    duration: '',
  });
  const [open, toggleOpen] = useState(false);

  useEffect(() => {
    if (!url) {
      return;
    }
    const tempUrl = new URL(url);
    const urlParams = new URLSearchParams(tempUrl.search);
    const id = urlParams.get('videoId');

    const getVideoData = async () =>
      await fetch(`https://wpt-brightcove-api.cloud.seek.com.au/video/${id}`)
        .then((response) => response.json())
        .then((data) => {
          setLoaded(true);
          setVideoData({
            thumb: data.poster,
            title: data.name,
            description: data.description,
            duration: data.duration,
          });
        })
        .catch((err) => setError(err));

    getVideoData();
  }, [url]);

  if (!url || error) {
    return (
      <Box
        className={styles.videoWrapper}
        borderRadius={'large'}
        display="flex"
        justifyContent="center"
      >
        <IconCritical size="large" tone="secondary" />
      </Box>
    );
  }

  return (
    <Box width="full">
      <Spread space="small" direction="vertical">
        <Box
          className={styles.videoWrapper}
          borderRadius={'large'}
          display="flex"
          justifyContent="center"
        >
          {!loaded && <Loader aria-label="video loading" size="large" />}
          <Box
            background="neutralLight"
            className={[
              styles.video,
              styles.borderRadius.large,
              styles.size.large,
            ]}
            borderRadius={'large'}
            style={{
              cursor: 'pointer',
              backgroundImage: `url(${videoData?.thumb})`,
              backgroundSize: 'cover',
            }}
            onClick={() => toggleOpen(true)}
          />
        </Box>
        {title ? (
          <Text size="large" weight="medium">
            {title}
          </Text>
        ) : null}
        {description ? <Text size="small">{description}</Text> : null}
      </Spread>
      {videoData && (
        // Switching the title and description around for initial SARA/STAR usage
        // but we should expand the Dialog options to allow for specific Dialog Title & Description
        <Dialog
          id="xyz"
          open={open}
          onClose={() => toggleOpen(false)}
          title={(description ? description : title) || ''}
          description={<Text>{title || ''}</Text>}
        >
          <Video url={url} type={VideoSrcType.Brightcove} />
        </Dialog>
      )}
    </Box>
  );
};
