import {
  Box,
  ContentBlock,
  Text,
  Inline,
  Columns,
  Column,
  Hidden,
  MenuItemLink,
  MenuRenderer,
  IconChevron,
  Link,
} from 'braid-design-system';
import React from 'react';
import { useLocation } from 'react-router';

import type { NavLinks } from '../../../types';

import SeekLogo from './seek-logo.png';

import * as styles from './SaraHeader.css';
import { ROUTES } from '../../../routes';

export const navLinks: NavLinks = [
  // Enter page can be shown again if we uncomment below line and routes in App.tsx, routes.ts.
  // { name: 'Enter', link: 'enter' },
  { name: 'Why enter', link: 'why-enter' },
  { name: 'Categories', link: 'categories' },
  { name: 'Entry tips', link: 'entry-tips' },
  // Winners & finalists page can be shown again if we uncomment below line.
  // { name: 'Winners & finalists', link: 'winners-finalists' },
  { name: 'Event', link: 'event' },
];

const SaraHeader = () => {
  const { pathname } = useLocation();
  const activeLink = pathname.slice(1);
  const activeLinkRegex = new RegExp(`(^${activeLink}$)`, 'g');

  return (
    <Box background="surface">
      <ContentBlock width="large">
        <Box paddingX="gutter" paddingY={['small', 'small', 'none']}>
          <Columns space="medium" alignY="center">
            <Column>
              <Link href={`/${ROUTES.BASE_NAME}`}>
                <Box
                  className={styles.logo}
                  style={{
                    backgroundImage: `url(${SeekLogo})`,
                  }}
                />
              </Link>
            </Column>
            <Column width="content">
              <Hidden above="tablet">
                <MenuRenderer
                  offsetSpace="small"
                  align="right"
                  trigger={(triggerProps, { open }) => (
                    <Box userSelect="none" cursor="pointer" {...triggerProps}>
                      <Text>
                        Menu{' '}
                        <IconChevron
                          direction={open ? 'up' : 'down'}
                          alignY="lowercase"
                        />
                      </Text>
                    </Box>
                  )}
                >
                  {navLinks.map((item, key) => (
                    <MenuItemLink
                      key={key}
                      href={`/${ROUTES.BASE_NAME}/${item.link}`}
                    >
                      {item.name}
                    </MenuItemLink>
                  ))}
                </MenuRenderer>
              </Hidden>
              <Hidden below="desktop">
                <Inline space="none" align="right">
                  {navLinks.map((item, key) => (
                    <Link key={key} href={`/${ROUTES.BASE_NAME}/${item.link}`}>
                      <Box
                        className={[
                          styles.link,
                          activeLinkRegex.test(item.link) ? styles.active : '',
                        ]}
                        paddingY="xlarge"
                        paddingX="small"
                        height="full"
                      >
                        <Text tone="neutral">
                          <span
                            className={
                              activeLinkRegex.test(item.link) ? styles.text : ''
                            }
                          >
                            {item.name}
                          </span>
                        </Text>
                      </Box>
                    </Link>
                  ))}
                </Inline>
              </Hidden>
            </Column>
          </Columns>
        </Box>
      </ContentBlock>
    </Box>
  );
};

export { SaraHeader };
