import { Box } from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import type React from 'react';

interface BurgerMenuProps {
  open: boolean;
  onClick: () => void;
}

export const BurgerMenu = ({ open, onClick }: BurgerMenuProps) => (
  <Box
    aria-label="Menu"
    aria-expanded={open}
    onClick={onClick}
    borderRadius="small"
    boxShadow={open ? 'borderBrandAccentLight' : undefined}
    cursor="pointer"
    display="flex"
    alignItems="center"
    justifyContent="center"
    style={{
      width: '40px',
      height: '40px',
    }}
  >
    <span style={{ fontSize: '40px', color: vars.foregroundColor.brandAccent }}>
      ☰
    </span>
  </Box>
);
