import {
  BackgroundColor,
  BorderRadius,
  type KeyStepsBlockFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Columns, Column, Stack } from 'braid-design-system';
import React from 'react';
import { Asset, AssetType } from '../Asset/Asset';
import { Heading } from '../Heading/Heading';
import { VerticalStepper } from './components/VerticalStepper';
import { vars } from 'braid-design-system/css';

type Props = KeyStepsBlockFragment;

export const KeyStepsBlock = ({ image, heading, steps, stepTone }: Props) => (
  <Box data={{ testid: 'KeyStepsId' }} height="full">
    <Columns space="xlarge" collapseBelow="tablet">
      <Column width="1/2">
        <Box
          width="full"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Stack space="small">
            {heading && (
              <Heading {...heading} data={{ testid: 'key-steps-heading' }} />
            )}
            {steps && (
              <VerticalStepper
                tone={stepTone || BackgroundColor.Neutral}
                steps={steps}
              />
            )}
          </Stack>
        </Box>
      </Column>
      <Column width="1/2" hideBelow="desktop">
        {image && (
          <Box display="flex">
            <Asset
              assetType={AssetType.DEFAULT_IMAGE}
              {...image}
              style={{
                maxWidth: 'fit-content',
                borderRadius: vars.borderRadius[BorderRadius.Xlarge],
              }}
            />
          </Box>
        )}
      </Column>
    </Columns>
  </Box>
);
