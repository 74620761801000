import {
  createAbsoluteUrl,
  getLocale,
  getSiteNameFromHostname,
  siteConfig,
  type Country,
  type Language,
  type Locale,
} from '@seek/melways-sites';
import type { Locale as HygraphLocale } from '@seek/cmsu-cms-connect';
import { useContext, createContext } from 'react';

import { ROUTES } from 'src/client/routes';
import type {
  Environment,
  EmployerSiteName,
  CandidateSiteName,
} from '../types';
import { convertMelwaysLocale } from '../helpers/convertMelwaysLocale';
import type { Request } from 'express';

export type ConfigStateContext = {
  country: Country;
  site: EmployerSiteName | CandidateSiteName;
  locale: Locale;
  hygraphLocale: HygraphLocale;
  language: Language;
  environment: Environment;
  basename: string;
  fullURL: string;
  product: 'sara' | 'star';
  routeArray: string[];
  hygraphContentApi: string;
  isDraft: boolean;
  recaptchaKey: string;
};

type ConfigProviderProps = {
  children: React.ReactNode;
  config: ConfigStateContext;
};

const ConfigContext = createContext<ConfigStateContext | undefined>(undefined);

const ConfigProvider = ({ children, config }: ConfigProviderProps) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

/**
 * Gets the config context.
 */
const useConfig = () => {
  const context = useContext(ConfigContext);
  /**
   * If this is used outside a child of ConfigProvider, throw an error.
   */
  if (context === undefined) {
    throw new Error('useConfigs must be used within a ConfigProvider');
  }

  return context;
};

export { ConfigProvider, useConfig };

/**
 * Creates a config that gets passed to the frontend via the global window variable.
 */
export function createConfig(req: Request): ConfigStateContext {
  const { hostname, originalUrl } = req;

  // Melways should give us the x-forwarded-host as a string. If it's not, this request likely isn't using Melways.
  const xForwardedHost =
    typeof req.headers['x-forwarded-host'] === 'string'
      ? req.headers['x-forwarded-host']
      : undefined;

  const site = getSiteName(xForwardedHost || hostname);
  const language = 'en';
  const locale = getLocale(site, language) as Locale;
  const country = siteConfig[site].classification.country as Country;
  const basename = `/${ROUTES.BASE_NAME}`;

  const product = originalUrl.includes('sara') ? 'sara' : 'star';
  const routeArray = [''].concat(originalUrl.split('/').filter((x) => x));

  const environment: Environment = req
    .get('host')
    ?.includes('wpt-sara-awards.webprod.dev.outfra.xyz')
    ? 'staging'
    : (process.env.NODE_ENV as Environment);

  const fullURL = createAbsoluteUrl({
    product: 'candidate',
    country,
    path: originalUrl,
    language,
    staging: environment === 'staging',
  });

  return {
    country,
    environment: process.env.NODE_ENV || ('production' as Environment),
    site,
    product,
    routeArray,
    language,
    locale,
    hygraphLocale: convertMelwaysLocale(locale) as HygraphLocale,
    basename,
    fullURL,
    hygraphContentApi: process.env.HYGRAPH_CONTENT_API || '',
    isDraft: false,
    recaptchaKey: '6Lc_dVwqAAAAAGr17R-ys3NOjsevO4VKxo1_9A7w',
  };
}

const getSiteName = (hostname: string) => {
  if (hostname === 'localhost') {
    // to handle testing on pipeline where hostname is localhost
    return 'candidate-seek-au' as CandidateSiteName;
  }
  return getSiteNameFromHostname(
    hostname.replace('.local', ''),
  ) as CandidateSiteName;
};
