import "../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.14_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@5.92.1/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62Sz1KDMBDG732KvThjD+nQv0q8+CadlGzpKiQYFkt1fHcnaVCKrfbgCUI+vv3t7jdZp09ubjZTeB8BMLYsVEG5kZChYXQPI4AdUr5jCdMkufHnUrViT5p3p5862SJJqvZh9DGaRO/Z/3nPh97zX7w3KnvOnW2MFg4rVCzB2Pg6uK/pDSVk1rAi4+/+7g/gTZDR2EpI0z7SIiDZSmXEBwnJ5OR2GW57pTNbWCfhVblbIdbTl1nx0vCsGQdC6zQ64ZSmph5qsnHf9y74aqqrQh0kbAsMjE9NzbQ9CN8aGv5t9JXSmkwu2FbDUvNxX7CxzLY8p/nGuT+P459Ck8OMyfpl2aIpw8DD9gQxlnUf8ux0+KRUepzocVQFbn1LVQu1LUif+TFC5M7uJUz7uz5Nlgq2F+v7TLiczI/NBbbHEjUpqDOHaEAZDbclmS7Zd4ukasfBv6uWhBOAV3WLWXVB++gJF1EYlzEsvwxoVwfsioh5UeReHYPSx1lehzM0+GtCaTobTqhrPCqWl1kuK1ZR8ZVLYw1Gok/syC8uCgUAAA==\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.14_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@5.92.1/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var BannerImageColumnStyle = '_9jr3nb6';
export var BannerTextColumnBoxStyle = '_9jr3nb4';
export var ShopfrontBannerTextColumnBoxStyle = '_9jr3nb5';
export var VerticalDivider = '_9jr3nb8';
export var VerticalDividerText = '_9jr3nba';
export var VerticalDividerWrapper = '_9jr3nb7';
export var VerticalLine = '_9jr3nb9';
export var bannerContainer = '_9jr3nb0';
export var headerImageStyle = '_9jr3nb2';
export var headerImageStyleEnlarged = '_9jr3nb1';
export var tertiaryImageStyle = '_9jr3nb3';