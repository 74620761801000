import 'braid-design-system/reset';
import { ConfigProvider, type ConfigStateContext } from './hooks/useConfig';
import {
  type ApolloClient,
  type NormalizedCacheObject,
  ApolloProvider,
} from '@apollo/client';
import { Outlet } from 'react-router';
import { BraidProvider } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { VocabProvider } from '@vocab/react';
import { MelwaysProvider } from '@seek/melways-react';
import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';

type Props = {
  client: ApolloClient<NormalizedCacheObject>;
  config: ConfigStateContext;
};

export const Root = ({ config, client }: Props) => (
  <MelwaysProvider.Server url={config.fullURL} site={config.site}>
    <ApolloProvider client={client}>
      <ConfigProvider config={config}>
        <VocabProvider language={config.language}>
          <CMSUProvider config={{ language: config.language }}>
            <BraidProvider theme={seekJobs}>
              <div role="main">
                <Outlet context={{ config, client }} />
              </div>
            </BraidProvider>
          </CMSUProvider>
        </VocabProvider>
      </ConfigProvider>
    </ApolloProvider>
  </MelwaysProvider.Server>
);
