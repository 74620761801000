import type { DialogButtonFragment } from '@seek/cmsu-cms-connect';
import {
  Button,
  Dialog as BraidDialog,
  Inline,
  Box,
  Text,
  IconCritical,
} from 'braid-design-system';
import React, { useState } from 'react';
import { renderIcon } from '../IconBlockGroup/renderer';
import { BlockItemRenderer, blockRenderers } from '../../renderer';

export const DialogButton = ({
  text,
  size,
  tone,
  variant,
  icon,
  iconPosition,
  dialogItem,
}: DialogButtonFragment) => {
  const [showDialog, toggleDialog] = useState(false);
  return (
    <Box paddingBottom="small">
      <Inline space="none" align="left">
        <Button
          onClick={() => toggleDialog(true)}
          tone={tone || undefined}
          variant={variant || 'solid'}
          size={size || 'standard'}
          icon={(icon && renderIcon(icon)) || undefined}
          iconPosition={iconPosition || undefined}
        >
          {text}
        </Button>
      </Inline>
      <BraidDialog
        id="braid-dialog"
        title={dialogItem?.title || 'Dialog title not provided'}
        description={dialogItem && <Text>{dialogItem.description}</Text>}
        open={showDialog}
        onClose={() => toggleDialog(false)}
      >
        {dialogItem ? (
          dialogItem.content.map((item, i) => {
            if (!item.__typename) return null;
            return (
              <BlockItemRenderer
                key={i}
                block={item as Required<typeof item>}
                renderers={blockRenderers.hirer}
                additionalData={{
                  fullURL: '',
                  page: { title: '' },
                }}
              />
            );
          })
        ) : (
          <Text icon={<IconCritical />}>Dialog content not provided</Text>
        )}
      </BraidDialog>
    </Box>
  );
};
