import { PageComponent } from '@seek/cmsu-components';
import type { GetPagesQuery } from '@seek/cmsu-cms-connect';
import React from 'react';
import { useLoaderData } from 'react-router';

import { useConfig } from 'src/client/hooks/useConfig';
import { pageBlockRenderers } from 'src/client/helpers/pageBlockRenderers';
import { PageLayout } from './PageLayout';

const Page = () => {
  const page = useLoaderData() as GetPagesQuery['pages'][0];
  const { language, fullURL, country, locale } = useConfig();

  return (
    <PageLayout page={page}>
      <PageComponent
        language={language}
        melwaysLocale={locale}
        page={page}
        fullURL={fullURL}
        renderers={pageBlockRenderers}
        country={country}
        subscriptionFormPrivacyUrl=""
        subscriptionFormSourceName=""
        wptSalesForceApi=""
      />
    </PageLayout>
  );
};

export { Page };
