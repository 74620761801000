import {
  BraidSizes,
  Theme,
  type ContainersFragment,
  type GetPagesQuery,
} from '@seek/cmsu-cms-connect';
import type { Country, Language } from '@seek/melways-sites';
import {
  Box,
  ContentBlock,
  Divider,
  HiddenVisually,
} from 'braid-design-system';
import React from 'react';

import {
  getBlockBorderProps,
  getBoxBackgroundProps,
  hasDivider,
} from './helper';
import { PromotionalBanner } from '../PromotionalBanner/PromotionalBanner';
import { SubscriptionForm } from '../SubscriptionForm/SubscriptionForm';
import { Tiles } from '../Tiles/Tiles';
import { CMSUProvider } from '../../hooks/useCMSUContext';
import {
  BlockItemRenderer,
  type BlockItemRenderers,
  BannerRenderer,
  type BannerRenderers,
} from '../../renderer';
import { universal } from '../../renderer/Banner/renderers';
import { SectionMenu } from '../SectionMenu/SectionMenu';
import { getBg } from './getBg';

export const PageComponent = ({
  bannerRenderers = universal,
  country,
  enabledSubscriptionForm,
  fullURL,
  language,
  melwaysLocale,
  page,
  renderers,
  subscriptionFormPrivacyUrl,
  subscriptionFormSourceName,
  wptSalesForceApi,
  utmParameters,
}: {
  bannerRenderers?: BannerRenderers;
  country: Country;
  enabledSubscriptionForm?: boolean;
  fullURL: string;
  language: Language;
  melwaysLocale: string;
  page: GetPagesQuery['pages'][0];
  renderers: BlockItemRenderers;
  subscriptionFormPrivacyUrl: string;
  subscriptionFormSourceName: string;
  wptSalesForceApi: string;
  utmParameters?: string;
}) => {
  const { title, promotionalBanner, banner, containers, relatedPageSection } =
    page;
  const { headerOptions, sectionName, theme } = relatedPageSection || {};

  const subNavLinks =
    headerOptions?.headerType?.__typename === 'SeekHeader' &&
    headerOptions.headerType?.subnavLinks;

  return (
    <CMSUProvider config={{ language, country, utmParameters }}>
      {subNavLinks ? (
        <SectionMenu currentPage={title} links={subNavLinks} />
      ) : null}
      <Box background="surface">
        <HiddenVisually>
          <h1>{title}</h1>
        </HiddenVisually>
        {/* Promotional Banner */}
        {promotionalBanner && <PromotionalBanner {...promotionalBanner} />}
        {/* Banner */}
        {banner && (
          <BannerRenderer
            renderers={bannerRenderers}
            banner={banner}
            country={country}
            theme={theme || Theme.SeekJobs}
          />
        )}
        {/* Containers */}
        {(containers as Array<ContainersFragment>).map(
          ({ id, box, htmlId, tile, blocks }, containerIdx) => (
            <React.Fragment key={id}>
              <Box
                textAlign={box?.textAlign || 'left'}
                paddingX={{
                  mobile: 'none',
                  tablet: 'none',
                  desktop: box?.paddingWidth || 'medium',
                }}
                className="sk-container"
                paddingY={box?.paddingHeight || 'xlarge'}
                id={htmlId || undefined}
                {...getBoxBackgroundProps(box?.background)}
              >
                <ContentBlock width={box?.width || 'medium'}>
                  <Box
                    {...getBlockBorderProps(box?.blockBorder)}
                    borderRadius={{
                      mobile: 'none',
                      tablet: box?.borderRadius || undefined,
                    }}
                    paddingX={box?.blockBorder ? 'gutter' : 'none'}
                    paddingY={box?.blockBorder ? 'medium' : 'none'}
                  >
                    <Tiles {...tile}>
                      {/* Blocks */}
                      {blocks.map((block, index) => {
                        const paddingProps = block.box?.background
                          ? {
                              paddingX: {
                                mobile: 'medium',
                                tablet: block.box?.paddingWidth || 'xlarge',
                              },
                              paddingY: block.box?.paddingHeight || 'xlarge',
                            }
                          : {
                              paddingX: {
                                mobile: 'small',
                                tablet: 'small',
                                desktop: 'none',
                              },
                            };

                        return (
                          // If the block is the first block in the container, use the container's paddingHeight.
                          // This enables us set the overall Page paddingY accurately.
                          <Box
                            key={block.id}
                            paddingY={
                              index === 0
                                ? box?.paddingHeight || 'small' // Handle the value being 'null'
                                : 'small'
                            }
                          >
                            <Box
                              textAlign={
                                block.box?.textAlign || box?.textAlign || 'left'
                              }
                              // Keep the padding to 'none' here when there is no background.
                              // Changing it will break alignment of tiles. To add padding to the blocks, adjust the padding of the parent box ^.
                              {...(paddingProps as React.ComponentProps<
                                typeof Box
                              >)}
                              className="sk-block"
                              {...getBoxBackgroundProps(block.box?.background)}
                              borderRadius={{
                                desktop: block.box?.borderRadius || 'none',
                                mobile: 'none',
                              }}
                            >
                              <ContentBlock
                                width={block.box?.width || 'medium'}
                              >
                                <Tiles
                                  {...block.tile}
                                  space={
                                    block.tile?.space ||
                                    block.stack?.space ||
                                    BraidSizes.Large
                                  }
                                >
                                  {block.items.map((item, idx) => (
                                    <Box
                                      background={
                                        block.tile?.background || undefined
                                      }
                                      borderRadius={
                                        block.tile ? 'large' : 'none'
                                      }
                                      height="full"
                                      key={idx}
                                      className="sk-block-item"
                                    >
                                      {block.box?.background &&
                                        block.box?.background === 'customBg' &&
                                        getBg()}

                                      <BlockItemRenderer
                                        block={item}
                                        renderers={renderers}
                                        additionalData={{
                                          page,
                                          fullURL,
                                        }}
                                      />
                                    </Box>
                                  ))}
                                </Tiles>
                              </ContentBlock>
                            </Box>
                          </Box>
                        );
                      })}
                    </Tiles>
                  </Box>
                </ContentBlock>
              </Box>

              {/* Divider */}
              {hasDivider(containers, containerIdx) && (
                <ContentBlock width="medium">
                  <Box textAlign="left" style={{ margin: 'auto' }}>
                    <Divider />
                  </Box>
                </ContentBlock>
              )}
            </React.Fragment>
          ),
        )}
        {enabledSubscriptionForm && (
          <Box
            marginTop={{
              mobile: 'gutter',
              tablet: 'xlarge',
              desktop: 'xxlarge',
            }}
            paddingBottom={{
              mobile: 'gutter',
              tablet: 'xlarge',
              desktop: 'xxlarge',
            }}
          >
            <SubscriptionForm
              type={sectionName!}
              locale={melwaysLocale}
              country={country}
              sourceName={subscriptionFormSourceName}
              privacyUrl={subscriptionFormPrivacyUrl}
              wptSalesForceApi={wptSalesForceApi}
            />
          </Box>
        )}
      </Box>
    </CMSUProvider>
  );
};
