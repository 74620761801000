import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Box,
  Button,
  ButtonIcon,
  Column,
  Columns,
  IconClear,
  IconInfo,
  Text,
  // TextLinkButton,
  useResponsiveValue,
} from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { ImageUI } from './APAC_Image';
import { DraggableCore, type DraggableData } from 'react-draggable';
import AddQuestionOutline from './assets/add-question.png';
import BehaviouralOutline from './assets/behavioural.png';
import MotivationOutline from './assets/motivation.png';
import SituationalOutline from './assets/situational.png';
import SkillsBasedOutline from './assets/skills-based.png';
import DragDrop from './assets/drag-drop.png';

import type { SelectedQuestion } from './APAC_Builder';
import { InterviewPDF } from './APAC_InterviewPDF';

const IS_CLIENT = typeof window !== 'undefined' && window.document;

interface SelectedQuestionsProps {
  activeList: SelectedQuestion[];
  removeActiveItem: (item: SelectedQuestion) => void;
  switchActiveItem: (index: number, newIndex: number) => void;
}

interface ItemPos {
  height: number;
  offsetTop: number;
}
const iconMapping: Record<string, string> = {
  motivation: MotivationOutline,
  behavioural: BehaviouralOutline,
  situational: SituationalOutline,
  skillsBased: SkillsBasedOutline,
  addQuestion: AddQuestionOutline,
};

export const SelectedQuestions = ({
  activeList,
  removeActiveItem,
  switchActiveItem,
}: SelectedQuestionsProps) => {
  const dropArea = React.useRef<HTMLDivElement>(null);
  const downloadRef = React.useRef<HTMLAnchorElement>(null);
  const [itemsPos, setItemsPos] = React.useState<ItemPos[]>([]);
  const [isStartDownload, setStartDownload] = React.useState<boolean>(false);
  const [isTouchingList, setTouchList] = React.useState<boolean>(false);
  const [pos, setPos] = React.useState<number>(0);
  const responsiveValue = useResponsiveValue();
  const isMobile = responsiveValue({
    mobile: true,
    tablet: false,
  });

  const onDragStart = () => {
    let touchlistTimer;
    if (typeof touchlistTimer === 'number') {
      clearTimeout(touchlistTimer);
    }

    if (dropArea.current) {
      const items = Array.from(dropArea.current.children) as HTMLDivElement[];
      const topOffsets = items.map((item) => ({
        height: item.clientHeight,
        offsetTop: item.offsetTop,
      }));
      setItemsPos(topOffsets);
      setPos(0);

      touchlistTimer = setTimeout(() => {
        setTouchList(true);
      }, 1000);
    }
  };

  const onDrag = (data: DraggableData, index: number) => {
    if (isMobile && isTouchingList) {
      const body = document.querySelector('body') as HTMLBodyElement;
      body.style.height = '100vh';
      body.style.overflow = 'hidden';
    }

    if (!isMobile || isTouchingList) {
      const newPos = pos + data.deltaY;
      const offsetTop = itemsPos[index].offsetTop + newPos;
      const height = itemsPos[index].height;
      let newIndex = itemsPos.findIndex(
        (item) => item.offsetTop >= offsetTop - height / 2,
      );
      newIndex = newIndex === -1 ? itemsPos.length - 1 : newIndex;

      if (dropArea.current) {
        const items = Array.from(dropArea.current.children) as HTMLDivElement[];
        items.forEach((item, i) => {
          item.style.touchAction = 'initial';

          if (i !== index) {
            item.style.zIndex = '0';
            item.style.transition = 'transform 0.3s linear';

            if (i < index && i >= newIndex) {
              item.style.transform = `translate(0px, ${height}px)`;
            } else if (i > index && i <= newIndex) {
              item.style.transform = `translate(0px, -${height + 1}px)`;
            } else {
              item.style.transform = `translate(0px, 0px)`;
            }
          } else {
            item.style.zIndex = '10';
            item.style.transition = 'none';
            item.style.transform = `translate(0px, ${newPos}px)`;
            setPos(newPos);
          }
        });
      }
    }
  };

  const onDragStop = (data: DraggableData, index: number) => {
    if (!isMobile || isTouchingList) {
      let timer;
      const newPos = pos + data.deltaY;
      const offsetTop = itemsPos[index].offsetTop + newPos;
      const height = itemsPos[index].height;
      let newIndex = itemsPos.findIndex(
        (item) => item.offsetTop >= offsetTop - height / 2,
      );
      newIndex = newIndex === -1 ? itemsPos.length - 1 : newIndex;

      if (typeof timer === 'number') {
        clearTimeout(timer);
      }

      if (dropArea.current) {
        const items = Array.from(dropArea.current.children) as HTMLDivElement[];
        items.forEach((item) => {
          item.style.zIndex = '0';
          item.style.transition = 'none';
          item.classList.remove('react-draggable', 'react-draggable-dragged');
          item.style.transform = `translate(0px, 0px)`;
        });
      }

      setTouchList(false);

      if (isMobile && isTouchingList) {
        const body = document.querySelector('body') as HTMLBodyElement;
        body.style.height = 'auto';
        body.style.overflow = 'auto';
      }

      if (newIndex !== index) {
        timer = setTimeout(() => {
          switchActiveItem(index, newIndex);
          setPos(0);
        }, 10);
      }
    }
  };

  const startDownload = () => {
    let clickTimer;
    let downloadTimer;
    if (typeof clickTimer === 'number') {
      clearTimeout(clickTimer);
    }
    if (typeof downloadTimer === 'number') {
      clearTimeout(downloadTimer);
    }
    if (activeList.length > 0) {
      setStartDownload(true);
      clickTimer = setTimeout(() => {
        if (downloadRef.current) {
          downloadRef.current.click();
        }
      }, 2000);
      downloadTimer = setTimeout(() => {
        setStartDownload(false);
      }, 3000);
    }
  };

  return (
    <>
      <Box marginBottom="medium">
        <Text icon={<IconInfo />} tone="info">
          Click and drag questions to re-order them
        </Text>
      </Box>
      <Box padding="medium" background="neutralSoftHover" marginBottom="gutter">
        <Box
          id="dropArea"
          background="surface"
          padding="xsmall"
          style={{ border: `2px solid ${vars.borderColor.neutralLight}` }}
        >
          {activeList.length === 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{
                border: `3px dashed ${vars.borderColor.formAccentLight}`,
                minHeight: '480px',
              }}
            >
              <Box style={{ maxWidth: '80px' }} marginBottom="small">
                <ImageUI imageUrl={DragDrop} alt="Drag and drop" />
              </Box>
              <Text size="small">
                Add a question from the panel to build your interview template
              </Text>
            </Box>
          ) : (
            <Box
              ref={dropArea}
              paddingX="small"
              style={{
                minHeight: '480px',
              }}
            >
              {activeList.map((item, index) => (
                <Box position="relative" key={item.question.slice(0, 40)}>
                  <DraggableCore
                    grid={[5, 5]}
                    onStart={onDragStart}
                    onDrag={(_E, data) => onDrag(data, index)}
                    onStop={(_E, data) => onDragStop(data, index)}
                  >
                    <Box
                      paddingY="medium"
                      paddingRight="medium"
                      style={{
                        borderBottom: `1px solid ${vars.borderColor.neutralLight}`,
                      }}
                    >
                      <Columns space="small" alignY="center">
                        <Column width="content">
                          <Box style={{ maxWidth: '20px' }}>
                            <ImageUI
                              imageUrl={iconMapping[item.parent]}
                              alt={item.parent}
                            />
                          </Box>
                        </Column>
                        <Column>
                          <Text size="small" component="span">
                            {item.question}
                          </Text>
                        </Column>
                      </Columns>
                    </Box>
                  </DraggableCore>
                  <Box
                    position="absolute"
                    right={0}
                    style={{ top: '50%', transform: 'translateY(-50%)' }}
                  >
                    <ButtonIcon
                      id="remove-question"
                      label="remove"
                      icon={<IconClear />}
                      variant="transparent"
                      onClick={() => {
                        removeActiveItem(item);
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Columns space="small" alignY="center">
        <Column width="content">
          <Box style={{ opacity: activeList.length === 0 ? 0.5 : 1 }}>
            <Button onClick={startDownload} loading={isStartDownload}>
              {isStartDownload ? 'Downloading' : 'Download my template'}
            </Button>
          </Box>
        </Column>
      </Columns>
      {IS_CLIENT && isStartDownload && (
        <PDFDownloadLink
          document={<InterviewPDF activeList={activeList} />}
          fileName="SEEK Interview template.pdf"
        >
          {/* Type issues as documented here: https://github.com/diegomura/react-pdf/issues/2886 */}
          {/* @ts-ignore */}
          {({ loading, url }) => {
            if (loading || !url) {
              return null;
            }

            return (
              <a
                ref={downloadRef}
                href={url}
                target="_blank"
                rel="noreferrer"
              />
            );
          }}
        </PDFDownloadLink>
      )}
    </>
  );
};
