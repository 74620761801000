export type SocialShareType = 'facebook' | 'twitter' | 'linkedin';

export interface SocialShareParams {
  pageUrl: string;
  title: string;
}

export const generateSocialShareUrl = (
  type: SocialShareType,
  { pageUrl, title }: SocialShareParams,
): string => {
  switch (type) {
    case 'facebook':
      return encodeURI(
        `https://www.facebook.com/sharer.php?u=${pageUrl}&p[title]=${title}`,
      );
    case 'twitter':
      return encodeURI(
        `https://twitter.com/share?text=${title}&url=${pageUrl}`,
      );
    case 'linkedin':
      return encodeURI(
        `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${title}`,
      );
    default:
      throw new Error(`Unsupported social share type: ${type}`);
  }
};
