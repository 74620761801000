import type { Country, Language } from '@seek/melways-sites';
import React, {
  useContext,
  createContext,
  type PropsWithChildren,
} from 'react';
import type { NestedLink } from './types';

const CustomHeaderContext = createContext<Props['config']>({
  showSiteNav: false,
  language: 'en',
  country: 'au',
  enableLanguageToggle: false,
  path: '',
  activeRoute: '',
  theme: 'default',
});

export const useCustomHeaderContext = () => {
  const context = useContext(CustomHeaderContext);

  if (context === null) {
    throw new Error(
      'useCustomHeaderContext must be used within a CustomHeaderProvider',
    );
  }

  return context;
};

type Props = PropsWithChildren<{
  config: {
    showSiteNav: boolean;
    language: Language;
    enableLanguageToggle: boolean;
    country: Country;
    path: string;
    activeRoute: string;
    links?: NestedLink[];
    theme: 'default' | 'sara' | 'star';
  };
}>;

export const CustomHeaderProvider = ({ config, children }: Props) => (
  <CustomHeaderContext.Provider value={config}>
    {children}
  </CustomHeaderContext.Provider>
);
